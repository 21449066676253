import * as React from 'react';
import { canResolvePendingGroups, memberWrapper } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { InjectedTranslateProps, translate } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { GroupPrivacyLevel } from '../GroupCard/GroupPrivacy';
import styles from './PendingGroupMeta.st.css';
import { GroupTitleLink } from '../GroupCard/Bricks';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';

interface PendingGroupMetaProps {
  group: ApiTypes.v1.GroupResponse;
  groupUrl: string;
}

export class PendingGroupMetaComponent extends React.Component<
  InjectedTranslateProps & PendingGroupMetaProps & WithCurrentUserProps
> {
  render() {
    const {
      currentUser,
      group: {
        groupId,
        details: { title },
        settings: { privacyLevel },
        createdBy,
        slug,
      },
      t,
      groupUrl,
    } = this.props;
    const { name, imageUrl } = memberWrapper(createdBy);
    const anonymous = t('groups-web.member.anonymous');

    const couldUserResolvePendingGroups = canResolvePendingGroups(currentUser);
    return (
      <div {...styles('root')}>
        <div {...styles(styles.header)}>
          <GroupTitleLink title={title} groupUrl={groupUrl} />
        </div>
        <p {...styles(styles.details)}>
          {couldUserResolvePendingGroups ? (
            <>
              <GroupPrivacyLevel t={t} privacyLevel={privacyLevel} />
              &nbsp;&middot;&nbsp;
              <span>
                {t('groups-web.group-list.pending-groups.created-by', {
                  authorName: name.nick || anonymous,
                })}
              </span>
            </>
          ) : (
            t('groups-web.group-list.pending-groups.status-for-creator')
          )}
        </p>
      </div>
    );
  }
}

const enhance = compose(translate(), withCurrentUser);

export const PendingGroupMeta = enhance(
  PendingGroupMetaComponent,
) as React.ComponentType<PendingGroupMetaProps>;
